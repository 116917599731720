@use 'sass:meta';
@use 'sass:string';

@mixin Always-Color($property, $args...) {
  .always {
    @each $name, $color in meta.keywords($args) {
      &\:#{$property}-#{$name} {
        &:hover,
        &:active,
        &:focus,
        &:visited {
          #{$property}: $color !important;
        }
      }
    }
  }
}

@mixin Colors($args...) {
  @each $name, $color in meta.keywords($args) {
    .#{$name} {
      color: $color;
    }
  }
}

@mixin Bg-Color($args...) {
  @each $name, $color in meta.keywords($args) {
    .#{$name} {
      background-color: $color !important;
    }
  }
}
