// size screen
$hd-pc-1: 1080px;
$hd-pc-2: 1280px;
$full-hd: 1920px;
$hd-pc-3: 1440px;
// font-size
$font-8: 10px;
$font-10: 10px;
$font-11: 11px;
$font-12: 12px;
$font-14: 14px;
$font-16: 16px;
$font-18: 18px;
$font-20: 20px;

// Colors
$dark: #000000;
$dark-1: #212529;
$black: #333333;
$white: #ffffff;
$white2: #f3f3f3;
$white3: #dfe1e5;
$red: #cc3636;
$red2: #9e7c7c;
$blue: #006cff;
$gray: #c4c4c4;
$gray2: #707070;
$gray-dark: #696969;
$yellow: #fff791;
$green: #39ac31;
$light-green: #92d050;
$green-register: #c5ffb7;
$blue-approval: #9dddff;
$orange-update: #ffdeb7;

$gray-100: #f2f2f2;
$gray-200: #e8e8e8;
$gray-300: #cccccc;
$gray-main: gray;
$gray-400: #f4f5f7;
$black-600: #444444;
$red-300: #ffafaf;
$yellow-500: #ffd26e;
$blue-300: #afdaff;
$blue-400: #b4c6e7;
$light-blue: #0d6efd;
$dark-shadow: #00000029;
$disable: #e9ecef;
$light-gray: #dbdbdb;
$white-green: #9dffa1;
$light-yellow: #ffe59d;
$light-pink: #ff9de1;
$light-brown: #c4c4c4;

$red-brown: #884343;
$dark-blue: #435388;

$red-cherry: #f70707;
$red-1: #cc3736;
$red-2: #fee5e5;
$red-3: #653333;
$red-4: #ce3e3e;
$red-dark: #cc3636;

$red-orange: #ed5c1a;
$orange-100: #ff9e88;
$header-bg: linear-gradient(180deg, #080606 0%, #66423f 100%);

$status-making: linear-gradient(180deg, rgba(255, 147, 147, 1) 0%, rgba(255, 175, 175, 0.4) 100%);
$status-applying: linear-gradient(180deg, rgba(253, 198, 76, 1) 0%, rgba(255, 210, 110, 0.4) 100%);
$status-approved: linear-gradient(180deg, rgba(162, 212, 255, 1) 0%, rgba(175, 218, 255, 0.5) 100%);

$bg-draft: linear-gradient(180deg, rgba(255, 147, 147, 1) 0%, rgba(255, 147, 147, 0) 100%);
$bg-registered: linear-gradient(180deg, rgba(162, 212, 255, 1) 0%, rgba(162, 212, 255, 0) 100%);

$bg-confirmation: #9dddff;
$bg-register: #c5ffb7;
$bg-change: #ffdeb7;

$header-shadow: rgba(0, 0, 0, 0.35);
$border: rgba(0, 0, 0, 0.2);
$border-gray: #e5e5e5;
$outline: #2684ff;
$lightBlue: #deebff;
$light-blue-1: #ccdcf1;
$blue-outline-1: #005cd9;
$blue-color-1: #002e6e;

$linear-black-to-600: linear-gradient(180deg, $black 0%, $black-600 100%);
$bg-loading: rgba(0, 0, 0, 0.6);
$shadow-black-200: rgba(32, 33, 36, 0.28) 0px 1px 6px 0px;

$bg-focus-none: #0b5ed7;
$bd-focus-none: #0a58ca;

$gray-fa: #fafafa;
$bg-blue-ff: #c1d6ff;
$bg-master-record: #E5EFFF;
