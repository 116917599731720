.spinning-wheel-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background: linear-gradient(to bottom right, #e6f3ff, #f0e6ff);
}

.wheel-wrapper {
  position: relative;
  width: 384px;
  height: 384px;
}

.wheel-background {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #2196f3;
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.3);
  padding: 12px;
}

.wheel {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  overflow: hidden;
  box-shadow: inset 0 5px 15px rgba(0, 0, 0, 0.2);
}

.segment {
  position: absolute;
  width: 50%;
  height: 50%;
  transform-origin: bottom right;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid black;
}

.segment-content {
  transform: rotate(-90deg);
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.segment-image {
  width: 64px;
  height: 64px;
  /* border-radius: 50%;
  object-fit: cover;
  border: 2px solid white;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  margin-top: -32px; */
}

.segment-name {
  font-size: 14px;
  margin-top: 8px;
  font-weight: bold;
  text-align: center;
  padding: 0 8px;
  white-space: nowrap;
}

.wheel-border {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 16px solid #1565c0;
  pointer-events: none;
}

.light-bulb {
  position: absolute;
  width: 12px;
  height: 12px;
  background-color: white;
  border-radius: 50%;
  box-shadow: 0 0 10px rgba(255, 255, 255, 0.8);
  animation: pulse 1s ease-in-out infinite alternate;
}

.wheel-pointer {
  position: absolute;
  top: 0;
  left: 50%;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 28px;
  border-color: #d32f2f transparent transparent transparent;
  transform: translateX(-50%) translateY(-50%);
  filter: drop-shadow(0 3px 3px rgba(0, 0, 0, 0.3));
}

.spin-button {
  margin-top: 48px;
  padding: 12px 32px;
  font-size: 20px;
  font-weight: bold;
  color: white;
  background: linear-gradient(to right, #ff9800, #f44336);
  border: none;
  border-radius: 9999px;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.spin-button:hover {
  transform: scale(1.05);
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
}

.spin-button:active {
  transform: scale(0.95);
}

.spin-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

@keyframes pulse {
  0% {
    opacity: 0.4;
  }
  100% {
    opacity: 1;
  }
}
