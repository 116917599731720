@import '~bootstrap/scss/bootstrap';
@import 'Static/Styles/variables.scss';
@import 'Static/Styles/mixins.scss';

/*
  * FONTS
*/
@font-face {
  font-family: 'elleFuturaBold';
  src: local('elle-futura-bold'),
    url(../Fonts/elle-futura-bold.otf) format('opentype');
}

@font-face {
  font-family: 'elleFuturaBook';
  src: local('elle-futura-book'), url(../Fonts/elle-futura-book.otf) format('opentype');
}

@font-face {
  font-family: 'SVN-Nexa-Bold';
  src: local('SVN-Nexa-Bold'),
    url(../Fonts/SVN-Nexa-Bold.ttf) format('opentype');
}


body {
  font-family: elleFuturaBook;
  font-size: $font-14;
}

/*
  * COLOR
*/
@include Colors($black: $black,
  $white: $gray-100,
  $red-300: $red-300,
  $gray-dark: $gray-dark,
  $gray: $gray,
  $red: $red,
  $blue: $blue);
@include Bg-Color($bg-red-300: $red-300,
  $bg-blue-300: $blue-300,
  $bg-yellow-500: $yellow-500,
  $bg-gray: $gray,
  $bg-black: $black);
@include Always-Color('color', $white: $white, $black: $black);
@include Always-Color('border-color', $gray: $gray);

.underline {
  content: '';
  position: absolute;
  bottom: 0;
  height: 1px;
}

.underline-100::after {
  @extend .underline;
  width: 100%;
  left: 0;
  background-color: $gray;
}

.underline-95::after {
  @extend .underline;
  left: 1%;
  width: 98%;
  background-color: $gray-200;
}

.flex-auto {
  flex: auto;
}

.flex-1 {
  flex: 1 1 auto !important;
}

.flex-0 {
  flex: 0 0 auto !important;
}

.z-index {
  z-index: 1 !important;
}

.h-max-content {
  height: max-content;
}

.overflow-x-auto {
  overflow-x: auto !important;
}

.bg-gray-dark {
  background: $gray-dark !important;
}

.bg-dark-1 {
  background: $dark-1 !important;
}

.bg-red-2 {
  background: $red-2 !important;
}

.bg-light-green {
  background: $light-green !important;
  border-color: $light-green !important;
}

.bg-light-green {
  background: $light-green !important;
  border-color: $light-green !important;
}
.bg-full-mobile {
  @media screen and (min-width: 1024px) {
  }
  @media screen and (max-width: 1024px) {
    height: 1015px;
  }
}
/* 
  * STYLED 
*/
* {
  margin: 0;
  padding: 0;
}

input[type='text'],
input::-webkit-input-placeholder {
  font-size: $font-14;
}

.btn,
.form-control {
  font-size: $font-14;
}

.bg-blue-ct {
  background-color: $light-blue;
}

.bg-blue-ct2 {
  background-color: $light-blue;
  opacity: 0.65;
}

.form-control {
  &:focus {
    border: 1px solid $outline;
    box-shadow: 0 0 0 1px $outline;
  }
}

.custom-progress-bar {
  height: 35px;

  .danger {
    background: $red-2 !important;
    border-color: $red-4 !important;
    color: $red-3 !important;
  }

  .primary {
    background: $light-blue-1 !important;
    border-color: $blue-outline-1 !important;
    color: $blue-color-1 !important;
  }
}

.custom-alert {
  padding: 0.375rem 0.75rem;
  background: $red-1;
  color: $white;
}

.card-container {
  box-shadow: 0px 3px 6px $dark-shadow;

  .card {
    border-radius: unset;
  }
}

/*
CUSTOM STYLE SELECT
*/
.css-yk16xz-control,
.css-1pahdxg-control {
  min-height: 30px !important;

  .css-g1d714-ValueContainer {
    .css-1uccc91-singleValue {
      padding-top: 0.375rem;
      padding-bottom: 0.375rem;
      line-height: 1.5;
    }
  }

  .css-tlfecz-indicatorContainer,
  .css-1gtu0rj-indicatorContainer {
    padding: 0 0.75rem !important;
  }
}

/*
END CUSTOM STYLE SELECT
*/

.text-white {
  color: $gray-100 !important;
}

.w-10 {
  width: 10% !important;
}

.w-15 {
  width: 15% !important;
}

.w-19 {
  width: 19% !important;
}

.w-20 {
  width: 20% !important;
}

.w-30 {
  width: 30% !important;
}

.w-40 {
  width: 40% !important;
}

.w-45 {
  width: 45% !important;
}

.w-50 {
  width: 50% !important;
}

.w-55 {
  width: 55% !important;
}

.w-60 {
  width: 60% !important;
}

.w-65 {
  width: 65% !important;
}

.w-70 {
  width: 70% !important;
}

.w-85 {
  width: 85% !important;
}

.w-90 {
  width: 90% !important;
}

.w-95 {
  width: 95% !important;
}

.mh-25 {
  min-height: 25px !important;
}

.h-18 {
  height: 18px !important;
}

.h-28 {
  height: 28px !important;
}

.h-30 {
  height: 30px !important;
}

.h-40 {
  height: 40px !important;
}

.w-130 {
  width: 130px !important;
}

.w-200 {
  width: 200px !important;
}

.w-300 {
  width: 300px !important;
}

.mw-35 {
  max-width: 35vw;
}

.mw-25 {
  max-width: 25vw;
}

.mw-300 {
  max-width: 300px;
}

.mw-600 {
  max-width: 600px;
}

.w-90px {
  width: 90px;
}

.mw-capacity {
  max-width: 96%;
}

.text-keep-all {
  word-break: keep-all;
}

.vh-layout {
  height: calc(100vh - 40px);
}

.sidebar {
  flex: 0 0 210px;
}

.w-btn-tab {
  width: 150px !important;
}

.w-btn-tab {
  width: 150px !important;
}

.btn-click {
  right: 10px;
  top: 0;
  bottom: 0;
  margin: auto;

  &:focus {
    box-shadow: none !important;
  }
}

p {
  margin-bottom: 0;
}

.required-star {
  font-size: $font-12;
}

.cursor-pointer {
  cursor: pointer;
}

img {
  width: 100%;
}

.container-fluid {
  padding: 0;
}

.text-feild-validate {
  color: $red-400;
}

.fs-8 {
  font-size: $font-8 !important;
}

.fs-11 {
  font-size: $font-11 !important;
}

.fs-12 {
  font-size: $font-12 !important;
}

.fs-14 {
  font-size: $font-14 !important;
}

.fs-16 {
  font-size: $font-16 !important;
}

.fs-18 {
  font-size: $font-18 !important;
}

.fs-20 {
  font-size: $font-20 !important;
}

.fw-medium {
  font-weight: 500 !important;
}

.text-gray-ct {
  color: $gray !important;
}

.text-dark {
  color: $dark !important;
}

.text-cherry {
  color: $red-cherry !important;
}

.text-dark-red {
  color: $red-dark !important;
}

.public-card-width {
  width: 380px;
}

.affix-icon {
  position: absolute;
  right: 10px;
  top: 10px;
}

.w-35 {
  width: 35% !important;
}

.w-50 {
  width: 50% !important;
}

.w-70 {
  width: 70% !important;
}

.max-w-85 {
  max-width: 85% !important;
}

.disable {
  background-color: $disable !important;
}

.bg-dark-blue {
  background: $dark-blue !important;
}

.bg-red-brown {
  background: $red-brown !important;
}

.bg-public {
  background-image: url(../Images/bg-public.png);
  background-size: cover;
  background-position: center;
}

.bg-smoke {
  background: $gray-100 !important;
}

.fs-7 {
  font-size: 0.875rem;
}

.fs-8 {
  font-size: 0.675rem;
}

.w-max-content {
  width: max-content !important;
}

.border-gray {
  border-color: $gray;
}

.border-transparent {
  border-color: transparent;
}

.division {
  width: 100%;
  height: 1px;
  background-color: $gray;
}

.btn-curator-register {
  width: 250px;
}

.row-reverse-margin {
  margin-left: calc((var(--bs-gutter-x) * -0.5)) !important;
  margin-right: calc((var(--bs-gutter-x) * -0.5)) !important;
}

.row {
  margin-left: 0;
  margin-right: 0;
}

.list-group-item {
  background: transparent;
  border: none;
  color: $dark;
  padding: 0;
}

.menu-btn {
  padding: 0;
  border: none;
  text-decoration: none;
}

.wrap-login {
  background-color: $white2;
}

.btn-shadow {
  box-shadow: 1px 2px 12px 0px $red2;
}

.border-1-ct {
  border: 1px solid $gray2;
}

.btn-dark-ct {
  background-color: $black !important;
}

.w-btn {
  width: 250px;
}

.w-btn-delete {
  width: 150px !important;
}

.form-control:disabled,
.form-control[readonly] {
  background-color: $white;
}

.d-inline-table {
  display: inline-table;
}

.check-box-ct {
  input {
    &:checked {
      background-color: $light-blue !important;
      border-color: $light-blue !important;
    }

    &:focus {
      box-shadow: none !important;
    }

    border-color: $black !important;
  }

  .form-check-label,
  .valid-feedback {
    color: $black !important;
    width: max-content;
  }

  .valid-feedback {
    font-size: $font-12;
    margin-left: 0px !important;
  }

  label {
    min-width: max-content;
  }
}

.button-submit {
  min-width: 202px;
}

.btn-show-modal {
  width: 291px;
  margin-top: 60px;
  margin-left: auto;
  margin-right: auto;
}

.h-40px {
  height: 40px;
}

.h-45px {
  height: 45px;
}

.h-50px {
  height: 50px;
}

.h-120px {
  height: 120px;
  resize: none;
}

.p-15px {
  padding: 15px !important;
}

.rounded-top-1 {
  border-top: 1px solid;
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}

.title-header-spacing {
  padding: 9px 16px;
}

.custom-text-small {
  font-size: $font-10;
}

.custom-border {
  border-color: $border !important;
}

.popover {
  max-width: 400px;
}

.d-contents {
  display: contents !important;
}
.mt-none-mb{
  margin-top: 0 !important;
}
/*
  Landing page
*/
.align-center {
  align-items: center;
}
.wrapper-verify {
  background: #0062b3;
}
.bg-modal-sm {
  background-color: #E31E93 !important;
}
.wrapper-play {
  background: rgb(49, 111, 196);
  min-height: 100vh;

  @media screen and (max-width: 1024px) {
    background: #0062b3;
  }
}

.wrapper-landing {
  font-family: elleFuturaBook, sans-serif;
  .play-game-sm {
    @media screen and (min-width: 1024px) {
      background-size: cover;
      background-image: url(../Images/bg_desktop_sm.webp);
    }
    @media screen and (max-width: 1024px) {
      background-size: cover;
      background-image: url(../Images/bg-landing-spin-mb_2025.webp);
      min-height: 72vh;
    }
  }

  .play-game-mbf {
    @media screen and (min-width: 1024px) {
      background-size: cover;
      background-image: url(../Images/mbf_bg_2025.webp);
    }
    @media screen and (max-width: 1024px) {
      background-size: cover;
      background-image: url(../Images/bg-landing-spin-mb_2025.webp);
      min-height: 72vh;
    }
  }
  .bg-bottom-mobile {
    @media screen and (max-width: 1024px) {
      background-size: cover;
      background-image: url(../Images/bg-landing-spin-mb_2025.webp);
      // height: 1015px;
    }
  }
  .bg-bottom-mobile.bg-saymee-mb {
    @media screen and (max-width: 1024px) {
      background-image: url(../Images/bg-saymee-spin-mb.webp);
    }
  }
  .bg-child-sm-2 {
    @media screen and (min-width: 1024px) {
      background-size: cover;
      background-image: url(../Images/bg-sm-desktop.webp);
    }
  }

  .bg-child-mbf-2 {
    @media screen and (min-width: 1024px) {
      background-size: cover;
      background-image: url(../Images/mbf_bg_2025.webp);
    }
  }

  .d-none-mobile {
    @media screen and (max-width: 1024px) {
      display: none;
    }
  }

  .d-none-desktop {
    @media screen and (min-width: 1024px) {
      display: none;
    }
  }

  .bg-header-saymee {
    background-color: #E31E93;

    button {
      border: none;
    }
  }
  .on-app {
    @media screen and (max-width: 1024px) {
      height: 4.55vw !important;
    }
  }

  .header-landing {
    height: 4.55vw;
    padding: 0 1.8vw 0 4.2vw;

    @media screen and (max-width: 1024px) {
      height: 18.3vw;
      padding: 4vw;
    }

    .logo-game {
      @media screen and (min-width: 1024px) {
        display: flex;
      }

      .sidebar {
        @media screen and (max-width: 1024px) {
          text-align: center;
          margin-bottom: 2vw;
        }
      }

      .img-logo-mb {
        width: 85%;

        @media screen and (max-width: 1024px) {
          width: 34.4vw;
          height: auto;
          margin: auto;
        }
      }

      .img-logo-sm {
        width: 5vw;

        @media screen and (max-width: 1024px) {
          width: 12vw;
          height: auto;
          margin: auto;
        }
      }
    }

    .btn-group-landing {
      display: flex;
      -webkit-box-pack: end;
      justify-content: flex-end;
      -webkit-box-align: center;
      align-items: center;
      width: 100%;

      @media screen and (max-width: 1024px) {
        justify-content: center;
        justify-content: space-around;
      }

      .btn-landing {
        font-family: SVN-Nexa-Bold, sans-serif;
        font-size: 0.95vw;
        text-align: center;
        text-transform: uppercase;
        display: block;
        color: #005db2;
        background: #fff;
        margin: 0 0.9vw;
        height: 2.6vw;
        width: 8.8vw;
        -webkit-border-radius: 0.5vw;
        -moz-border-radius: 0.5vw;
        border-radius: 0.5vw;
        font-weight: 600;
        padding: 0;

        @media screen and (max-width: 1024px) {
          margin: 0;
          font-size: 1.98vw;
          height: 5.2vw;
          width: 17.3vw;
          -webkit-border-radius: 1.5vw;
          -moz-border-radius: 1.5vw;
          border-radius: 1.5vw;
        }
      }
    }
  }

  .space {
    @media screen and (min-width: 1024px) {
      min-height: 165vh;
    }

    @media screen and (max-width: 1024px) {
      min-height: 117vw;
    }
  }

  .main {
    min-height: 48.46875vw;
    font-size: 1.30208vw;

    @media screen and (min-width: 1024px) {
      display: flex;
    }

    @media screen and (max-width: 1024px) {
      display: block;
      padding-bottom: 90vw;
    }

    .left {
      order: 2;
      width: 47vw;
      padding-top: 46.7vw;
      font-size: 1.5vw;
      color: #ffffff;

      @media screen and (max-width: 1024px) {
        // display: none;
      }

      .text-center {
        margin-top: 2vw;

        .date-range {
          font-family: elleFuturaBold, sans-serif;
        }
      }

    }

    .right {
      
      @media screen and (max-width: 1024px) {
        width: 90%;
        margin: auto;
      }

      .box-info {
        width: 40.89vw;
        color: #ffffff;
        -webkit-border-radius: 3.04167vw;
        -moz-border-radius: 3.04167vw;
        border-radius: 3.04167vw;
        padding: 4vw 2.34vw;
        font-size: 20px;
        text-align: justify;
        margin: 12.2vw 2.3vw 0 4vw;
        // background-color: rgb(45 145 193 / 74%);
        background-color: rgba(255, 255, 255, 0.4);
        box-shadow: rgba(100, 100, 111, 3.2) 0px 7px 29px 0px;

        @media screen and (max-width: 1024px) {
          width: 100%;
          color: #ffffff;
          border: none;
          padding: 18px;
          font-size: 3.7vw;
          margin: 0 3.3vw 0 0;
          border-radius: 6vw;
        }

      }

      .checkbox-accept {
        font-family: elleFuturaBold, sans-serif;

        input {
          @media screen and (min-width: 1024px) {
            margin-top: 7px;
          }

          @media screen and (max-width: 1024px) {
            margin-top: 5px;
          }
        }

        input,
        label {
          cursor: pointer;
        }

        .form-check-input:checked {
          background-color: #eb305a;
          border-color: #eb305a;
        }
      }

      .button-verify-phone {
        background-image: linear-gradient(134deg, #ffe1e1, #ffff2c, #f9eeec);
        font-family: elleFuturaBold, sans-serif;
        color: #0478c1;
        border-radius: 3.5vw;
        font-size: 1.3vw;
        width: 9vw;
        height: 3vw;

        @media screen and (max-width: 1024px) {
          width: 30vw;
          height: 10vw;
          font-size: 4vw;
          border-radius: 5vw;
        }
      }

      .code-spin {
        font-weight: 600;
        color: #fff;
        @media screen and (min-width: 1024px) {
          font-size: 22px;
        }
        @media screen and (max-width: 1024px) {
          font-size: 5vw;
        }
      }
    }
    
    .text-decoration-underline {
      cursor: pointer;
      font-weight: 600;
      color: #f1cd0c;
    }
  }

  .game-box {
    position: relative;
    margin-top: 4.2vw;
    margin-right: 12vw;
    margin-left: 4vw;
    width: 30vw;
    @media screen and (max-width: 1024px) {
      width: 85%;
      margin: 56vw auto 0;
    }

    // .arrow {
    //   width: 2.8vw;
    //   height: 7.65625vw;
    //   background: url(../Images/arrow.png) no-repeat 0 0/contain;
    //   position: absolute;
    //   z-index: 2;
    //   left: 50%;
    //   top: 0;
    //   -webkit-transform: translateX(-50%);
    //   -moz-transform: translateX(-50%);
    //   -ms-transform: translateX(-50%);
    //   -o-transform: translateX(-50%);
    //   transform: translateX(-50%);

    //   @media screen and (max-width: 1024px) {
    //     width: 8.208333vw;
    //     height: 10.65625vw;
    //   }
    // }
    // .arrow {
    //   width: 5vw;
    //   height: 7.65625vw;
    //   background: url(../Images/arrow_2025.png) no-repeat 0 0/contain;
    //   position: absolute;
    //   z-index: 2;
    //   left: 2%;
    //   top: 46%;
    //   -webkit-transform: translateX(-50%);
    //   -moz-transform: translateX(-50%);
    //   -ms-transform: translateX(-50%);
    //   -o-transform: translateX(-50%);
    //   transform: translateX(-50%);

    //   @media screen and (max-width: 1024px) {
    //     width: 8.208333vw;
    //     height: 10.65625vw;
    //   }
    // }
    // .bg-arrow {
    //   width: 5vw;
    //   height: 7.65625vw;
    //   background: url(../Images/bg-arrow_2025.png) no-repeat 0 0/contain;
    //   position: absolute;
    //   z-index: 0;
    //   left: 2%;
    //   top: 41%;
    //   -webkit-transform: translateX(-50%);
    //   -moz-transform: translateX(-50%);
    //   -ms-transform: translateX(-50%);
    //   -o-transform: translateX(-50%);
    //   transform: translateX(-50%);

    //   @media screen and (max-width: 1024px) {
    //     width: 8.208333vw;
    //     height: 10.65625vw;
    //   }
    // }

    // canvas {
    //   background: url(../Images/bg-wheel_2025.webp) no-repeat 0 0/contain;
    //   -webkit-transform: translate3d(0, 0, 0) rotateZ(0deg);
    //   -moz-transform: translate3d(0, 0, 0) rotateZ(0deg);
    //   transform: translate3d(0, 0, 0) rotateZ(0deg);
    //   -webkit-transition: transform 3s ease-out;
    //   width: 35.6vw;
    //   height: 35.6vw;
    //   margin: auto;
    //   display: block;
    //   margin-top: 5vw !important;

    //   @media screen and (max-width: 1024px) {
    //     width: 100%;
    //     height: 100%;
    //   }
    // }

    .game-box {
      position: relative;
      width: 40vw;
      height: 40vw;
      max-width: 500px;
      max-height: 500px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: auto;
    }
    
    .wheel-container {
      position: relative;
      width: 100%;
      height: 100%;
    }
    
    canvas {
      width: 100%;
      height: 100%;
      background: url(../Images/bg-wheel_2025.webp) no-repeat center/contain;
      transform: translate3d(0, 0, 0) rotateZ(0deg);
      transition: transform 3s ease-out;
    }
    
    .arrow,
    .bg-arrow {
      position: absolute;
      width: 6vw;
      height: 9vw;
      max-width: 50px;
      max-height: 75px;
      // left: 50%;
      top: 37%;
      transform: translateX(-50%);
    }
    
    .arrow {
      background: url(../Images/arrow_2025.png) no-repeat center/contain;
      z-index: 3; /* Đảm bảo mũi tên luôn trên cùng */
      left: 2%;
    }
    
    .bg-arrow {
      background: url(../Images/bg-arrow_2025.png) no-repeat center/contain;
      z-index: 0;
    }



    .btn-spin {
      background: white;
      background-image: linear-gradient(#ffd3d3, #ffff36);
      font-family: elleFuturaBold, sans-serif;
      color: #0a58ca;
      border-radius: 3.5vw;
      font-size: 1.5vw;
      width: 16vw;
      height: 4vw;
      box-shadow: 0px 2px 16px 0 rgb(107 51 51 / 80%), 17px -13px 59px 4px rgb(199 150 150 / 19%);

      @media screen and (max-width: 1024px) {
        width: 45vw;
        height: 10vw;
        font-size: 4vw;
        border-radius: 5vw;
      }
    }
  }

  footer {
    background: #154d9f;
  }

  .footer-mbf {
    color: #ffffff;

    .icon-footer {
      @media screen and (max-width: 1024px) {
        width: 32px !important;
        height: 32px !important;
      }
      @media screen and (min-width: 1024px) {
        width: 28px !important;
        height: 28px !important;
      }

    }
    
    .desktop {
      font-size: 0.9vw;
      @media screen and (max-width: 1024px) {
        display: none !important;
      }
    }

    .mobile {
      font-size: 3vw;
      @media screen and (min-width: 1024px) {
        display: none !important;
      }
    }

    .top {
      border-bottom: solid 1px darkseagreen;
    }

    a span {
      color: #ffffff;
    }

    a span:hover {
      color: pink;
    }

    hr {
      border-top: 1px solid #ffffff;
    }

    .icon-mark-ft {
      width: 18px;
      height: 24px;
      display: inline-block;
      margin: 0 8px 0 0;
      background: url(../Images/all-icon.png) no-repeat -500px -100px;
    }

    .icon-phone-ft {
      width: 18px;
      height: 24px;
      display: inline-block;
      margin: 0 8px 0 0;
      background: url(../Images/all-icon.png) no-repeat -500px -182px;
    }

    .icon-scanner-ft {
      width: 18px;
      height: 24px;
      display: inline-block;
      margin: 0 8px 0 0;
      background: url(../Images/all-icon.png) no-repeat -500px -318px;
    }

    .icon-phone-hot-ft {
      width: 18px;
      height: 24px;
      display: inline-block;
      margin: 0 8px 0 0;
      background: url(../Images/all-icon.png) no-repeat -420px -1348px;
    }

    .call {
      position: absolute;
      margin-bottom: 5px;
    }

   
  }

  .footer-saymee {
    background-color: #E31E93;
    color: #ffffff;

    a span {
      color: #ffffff;
    }

    a span:hover {
      color: pink;
    }

    .desktop {
      font-size: 0.8vw;
      @media screen and (max-width: 1024px) {
        display: none !important;
      }
    }

    .mobile {
      font-size: 3vw;
      @media screen and (min-width: 1024px) {
        display: none !important;
      }
    }
  }
}
.box-info-result{
  padding: 5px;
  color: #ffffff;
  border-radius: 20px;
  background-color: rgba(255, 255, 255, 0.4);
  box-shadow: #64646f 0px 7px 29px 0px;
  @media screen and (max-width: 1024px) {
    width: 100%;
  }
  @media screen and (min-width: 1024px) {
    width: 50%;
    float: right;
    margin-top: 30px;
  }
}
.modal-landing {
  .modal-content {
    
    background: #0897db ;
    @media screen and (min-width: 1024px) {
      font-size: 17px !important;
    }
  
    @media screen and (max-width: 1024px) {
      font-size: 3.7vw !important;
    }
    
  }
  .modal-content.bg-modal-sm{
    background: #E31E93 !important;
  }
  .guide-note,
  .faq,
  .rule-note {
    color: white;
    text-align: justify;

    a {
      color: white;
    }
  }

  .table-landing-page-prizes,
  .table-landing-page-result {
    color: white;
    text-align: center;

    .table-responsive {
      max-height: 100vw;
    }
  }
}

.modal-error {
  color: white;
}
.bg-ipad{
  @media screen and (max-width: 1024px) {
    // background-image: url(../Images/bg-result-ipad-mb.png) no-repeat !important;
    background-image: url(../Images/bg-result-ipad-mb.png) !important;
    background-repeat: no-repeat !important;
    height: 565px;
  }
  @media screen and (min-width: 1024px) {
    background-image: url(../Images/bg-ipad.png) !important;
    background-repeat: no-repeat !important;
  }
}
.bg-apple-watch{
  @media screen and (max-width: 1024px) {
    background-image: url(../Images/bg-result-app-watch-mb.png) !important;
    background-repeat: no-repeat !important;
    height: 565px;
  }
  @media screen and (min-width: 1024px) {
      background-image: url(../Images/bg-apple-watch.png) !important;
    background-repeat: no-repeat !important;
  }
}
.modal-spin-result {
  
  .modal-content {
    @media screen and (max-width: 1024px) {
      background: url(../Images/popup_prize.png) no-repeat 0 0/contain;
      // background-size: 115%;
      background-position-x: center;
      background-position-y: center;
      padding: 45px 8vw 5vw 8vw;
      font-size: 3.5vw;
      border: none;

      .text-header {
        font-size: 4.5vw;
      }
    }

    @media screen and (min-width: 1024px) {
      background: url(../Images/popup_prize.png) no-repeat 0 0/contain;
      // background-size: 103%;
      background-position-x: center;
      background-position-y: center;
      min-height: 28vw;
      padding: 25px 69px;
      border: none;
    }
   
  }

  .spin-result {
    color: white;
  }

  .wrapper-fail {
    @media screen and (max-width: 1024px) {
      padding: 5vw 0 5vw;
      .spin-result {
        // padding-bottom: 15vw;
        font-size: 4.5vw;
      }
    }
    @media screen and (min-width: 1024px) {
      padding: 6vw 0 5vw;
      .spin-result {
        font-size: 1.5vw;
        margin-bottom: 4rem;
      }

    }
  }
}

.text-header {
  font-family: SVN-Nexa-Bold, sans-serif;
  text-transform: uppercase;
  color: #ffde4b;
}

.text-bold {
  font-family: SVN-Nexa-Bold, sans-serif;
  text-transform: uppercase;
}

.text-strong {
  font-family: elleFuturaBold, sans-serif;
}

.modal-landing-verify-phone {
  justify-content: center;

  @media screen and (min-width: 1024px) {
    width: 40vw;
  }
  
  .modal-content {
    border: none;
    color: white;
    background: #0897db !important;
    input, input::placeholder {
      font-size: 1.4vw;

      @media screen and (max-width: 1024px) {
        font-size: 4vw;
      }
    }
    .phone-number {
      border-radius: 2em !important;
    }

    .btn-cancel,
    .btn-verify {
      border-radius: 2em;
      min-width: 30vw;
      font-size: 4vw;

      @media screen and (min-width: 1024px) {
        min-width: 15vw;
        font-size: 1.5vw;
      }
    }

    .btn-verify {
      border: none;
      background-image: linear-gradient(#ff6e6e, #cbcb32);
    }
  }
  .modal-content.bg-modal-sm{
    background: #E31E93 !important;
  }
}

/*
  End Landing page
*/
/*
  *  MODAL INSIDE MODAL
*/

@for $i from 1 through 6 {
  $zIndexBackdrop: #{1000 + (5 * $i)};
  $zIndexContent: #{1000 + (5 * $i) + 2};

  .modal-backdrop.show:nth-of-type(#{$i}) {
    z-index: $zIndexBackdrop;
  }

  div[role='dialog'][aria-modal='true']:nth-of-type(#{$i}) {
    z-index: $zIndexContent;
  }
}

/*
  *  BTN
*/

.btn {
  &:active {
    border-color: transparent !important;
  }

  &:focus {
    box-shadow: none;
  }
}

.btn-focus-none {
  &.btn {
    background-color: $light-blue;

    &:hover {
      background-color: $bg-focus-none;
      border-color: $bd-focus-none;
    }
  }
}

.btn-gray-dark {
  background: $gray-dark !important;
  border-color: $gray-dark !important;

  &:active {
    box-shadow: 0 0 0 0.25rem rgb(105 105 105 / 50%) !important;
  }
}

.bg-red-orange {
  background-color: $red-orange !important;
  border-color: $red-orange !important;
}

.centered-axis-x {
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
}

/*
  * DatePicker
*/
.react-datepicker {
  min-width: max-content !important;
}

.react-datepicker-popper {
  z-index: 1200;
}

.react-datepicker__input-container {
  input {
    font-size: $font-14;
    color: $black;
    padding-right: 30px;
  }
}

.react-time-picker__wrapper {
  border: 0 !important;
}

.date-range-picker-icon {
  @extend .centered-axis-x;
  top: 53%;
}

/*
  *  MAIN WRAP FORM
*/

.main-wrap-form {
  box-shadow: 0px 3px 6px $dark-shadow;

  .box-title-header {
    display: flex;
    align-items: center;
  }

  .box-form-content {
    .box-image {
      width: 100%;
      height: 100%;
      max-width: 300px;
      max-height: 300px;
      object-fit: cover;
      aspect-ratio: 3/3;

      &.box-file-border {
        border: 1px solid $gray;
      }
    }
  }
}

.box-form-no-shadow {
  box-shadow: unset;

  .card-body {
    padding: 0 !important;
  }
}

/*
  * MAIN WRAP MODAL
*/

.main-wrap-modal {
  .modal-header {
    height: 50px;
    background-color: $black;
    padding-left: 24px;

    .title-header {
      font-size: 16px;
      color: $white;
    }
  }

  .content-body-modal {
    min-height: 170px;
  }
}

/*
  * 404 page
*/

/*======================
    404 page
=======================*/

.link-404 {
  color: $white !important;
  background: $green;
  text-decoration: none;
  border-radius: 8px;
  font-size: 16px;
}

.nav-link,
.btn-link {
  background: transparent !important;
  color: $gray-100;

  &:focus,
  &:active,
  &:hover {
    color: $gray-100;
  }
}

.menu-children {

  &:focus,
  &:active,
  &:hover {
    color: $gray-100;
    background: $blue !important;
  }

  &.active {
    background: $blue !important;
  }
}

.border-bottom-1 {
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  -webkit-background-clip: padding-box;
  /* for Safari */
  background-clip: padding-box;
  /* for IE9+, Firefox 4+, Opera, Chrome */
}

.border-bottom-2 {
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  -webkit-background-clip: padding-box;
  /* for Safari */
  background-clip: padding-box;
  /* for IE9+, Firefox 4+, Opera, Chrome */
}

/*
  * HIDE SCROLLBAR
*/

.br-custom-scrollbar {
  -ms-overflow-style: none;
  /* Internet Explorer 10+ */
  scrollbar-width: none;

  /* Firefox */
  &::-webkit-scrollbar {
    display: none;
    /* Safari and Chrome */
  }
}

/*
 * PAGINATION
*/

.pagination {
  margin-bottom: 0;

  .page-link {
    color: $dark;
    background-color: transparent;
    border-color: $border;
  }

  .page-item.active .page-link {
    color: $white;
    background-color: $dark;
    border-color: $border;
  }
}


.market-card {
  @media screen and (min-width: 319px) {
    height: auto;
    width: 100%;
  }

  @media screen and (min-width: 767px) {
    width: 420px;
  }

  @media screen and (min-width: 991px) {
    width: 470px;
    height: 260px;
  }

  .market-content {
    @media screen and (min-width: 319px) {
      width: 100%;
      margin-bottom: 20px;
    }

    @media screen and (min-width: 1365px) {
      margin-bottom: 0;
    }
  }
}

.calendar-divide {
  &::before {
    content: '~';
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  }
}

/*
  * HEADER TOP
*/

.header-top {
  background: $header-bg;
  background-repeat: repeat-y;
  padding-left: 0;
  padding-top: 2.5px;
  padding-bottom: 2.5px;

  .company-name {
    max-width: 450px;
  }

  .email {
    max-width: 300px;
  }

  .img-logo {
    width: 100px;
  }
}

.btn-action {
  width: 250px;
}

.btn-action-big {
  width: 300px;
}

/*
  * END HEADER TOP
*/

.btn-excel {
  width: 150px;
}

.form-with-label-nowrap {
  >label {
    white-space: nowrap;
  }
}

/* HIDE SRCOLL*/
.view-scroll {
  height: calc(100vh - 115px);
}

.hidden-scroll-nft-table {
  &::-webkit-scrollbar {
    display: none;
  }
}

@mixin make-container() {
  width: 100%;
  padding-right: 25px;
  padding-left: 25px;
  margin-right: auto;
  margin-left: auto;
}

.table-nft-container {
  @include make-container();
}

.h-35px {
  height: 35px;
}

.table-radius {
  border-radius: 0.25rem 0.25rem 0.25rem 0.25rem;
  -moz-border-radius: 0.25rem 0.25rem 0.25rem 0.25rem;
  -webkit-border-radius: 0.25rem 0.25rem 0.25rem 0.25rem;

  thead {
    tr:first-child {
      border-radius: 0.25rem 0.25rem 0 0;

      th:first-child {
        border-radius: 0.25rem 0 0 0;
        -moz-border-radius: 0.25rem 0 0 0;
        -webkit-border-radius: 0.25rem 0 0 0;
      }

      th:last-child {
        border-radius: 0 0.25rem 0 0;
        border-color: $dark-1;
        -moz-border-radius: 0 0.25rem 0 0;
        -webkit-border-radius: 0 0.25rem 0 0;
      }
    }
  }

  tr:last-child {
    border-radius: 0 0 0.25rem 0.25rem;

    td:first-child {
      border-radius: 0 0 0 0.25rem;
      -moz-border-radius: 0 0 0 0.25rem;
      -webkit-border-radius: 0 0 0 0.25rem;
    }

    td:last-child {
      border-radius: 0 0 0.25rem 0;
      -moz-border-radius: 0 0 0.25rem 0;
      -webkit-border-radius: 0 0 0.25rem 0;
    }
  }
}

/*
  * SPINER LOADING
*/
.spinner-component {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999;
  background: $bg-loading;
  backdrop-filter: blur(0.2rem);
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .spinner-border {
    width: 3rem;
    height: 3rem;
    z-index: 1003;
    border-width: 4px;
  }
}

/*
  * Ellipsis
*/
.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
}

.txt-require {
  color: $red-cherry;
}

.text-disable {
  background-color: $light-gray !important;
  color: $gray-dark;
}

/*
  * SEARCH
*/

.input-search-wrapper {

  &:hover,
  &:focus {
    .input-search {
      box-shadow: $shadow-black-200;
    }
  }

  .icon-search {
    width: 14px;
    height: 14px;
    top: 50%;
    left: 10px;
    transform: translateY(-50%);
  }

  .icon-down {
    @extend .icon-search;
    left: auto;
    right: 10px;
  }

  .input-search {
    padding-left: 30px;
    padding-right: 30px;
    border-color: $white3 !important;

    ::placeholder {
      color: $gray-main;
      opacity: 1;
    }

    &:focus {
      border-color: $white3 !important;
      box-shadow: $shadow-black-200 !important;
    }

    &::-webkit-search-cancel-button {
      position: relative;
      -webkit-appearance: none;
      height: 9px;
      width: 9px;
      background: url(../Images/remove.svg) no-repeat 50% 50%;
      cursor: pointer;
    }
  }
}

.search-results {
  top: 100%;
  background-color: $white;
  border-radius: 4px;
  box-shadow: rgb(0 0 0 / 10%) 0px 0px 0px 1px, rgb(0 0 0 / 10%) 0px 4px 11px;
  margin-bottom: 8px;
  margin-top: 8px;
  position: absolute;
  width: 100%;
  z-index: 9999;
  box-sizing: border-box;

  ul {
    margin: 6px 0;
    padding: 0;
    max-height: 500px;
    overflow-y: auto;
  }

  .result-option {
    color: inherit;
    cursor: pointer;
    display: block;
    font-size: inherit;
    padding: 8px 12px;
    width: 100%;
    user-select: none;
    -webkit-tap-highlight-color: $dark;
    box-sizing: border-box;

    &:hover {
      background-color: $lightBlue;
    }
  }
}

.icon-searchId {
  top: 75% !important;
  left: 20px !important;
}

/*
  * Popup Bonus
*/
.seats-end::after {
  content: '番';
  position: absolute;
  z-index: 1;
  right: -20px;
  top: 5px;
}

// *  CAMPAIGN

.register-campaign {
  .item-step {
    clip-path: polygon(92% 0, 100% 50%, 92% 100%, 0% 100%, 8% 50%, 0 0);
    width: 190px;
    height: 60px;
    margin-right: 1rem;

    &:first-child {
      clip-path: polygon(92% 0, 100% 50%, 92% 100%, 0% 100%, 0 50%, 0 0);
    }

    &:last-child {
      margin-right: 0 !important;
    }

    .nav-link {
      border-radius: 0 !important;
      color: $black !important;

      &.active {
        background-color: $black !important;
        color: $white !important;
      }
    }
  }
}

.nav-step {
  width: 100%;
  padding-right: var(--bs-gutter-x, 0.75rem);
  padding-left: var(--bs-gutter-x, 0.75rem);
  max-width: 1320px;

  .item-step {
    width: 24%;
  }
}

.register-campaign-step-2 {
  .valid-feedback {
    width: auto;
    white-space: pre-wrap;
  }

  .price-setting-for-1-ticket {
    &::after {
      @extend .miim-align-items-center;
      content: '円の購入で１応募権利を取得できます';
    }
  }

  .people-amount {
    &::after {
      position: absolute;
      z-index: 1;
      right: -5px;
      top: 25%;
      font-size: $font-12;
      content: '人';
    }
  }

  .standard-time-des {
    &::after {
      @extend .miim-align-items-center;
      height: 35px;
      content: 'に抽選処理を実行します';
    }
  }
}

.miim-align-items-center {
  display: flex;
  align-items: center;
}

.probability-of-prize::after {
  @extend .miim-align-items-center;
  content: '%';
}

.modal-award {
  .preview-image {
    max-width: 250px;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
  }
}

.swiper {
  width: 100%;
  height: 100%;
  min-height: 300px;
}

.swiper-slide {
  text-align: center;
  font-size: $font-14;

  a {
    text-decoration: none !important;
    color: unset !important;
  }

  .fc-col-header-cell {
    background: $gray-dark !important;

    a {
      color: $white !important;
    }
  }

  .fc-header-toolbar {
    margin-bottom: 8px !important;

    .fc-toolbar-title {
      font-size: $font-14;
    }
  }
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.swiper-button-next,
.swiper-button-prev {
  height: 80% !important;
  background: #bebcbc !important;
  top: 50% !important;
  transform: translateY(-50%);
  border-radius: 4px;

  &::after {
    color: #3e3d3e !important;
    font-size: 14px !important;
  }
}

.w-1-3 {
  width: calc(100% / 3);
}

.w-1-5 {
  width: calc(100% / 5);
}

.header-light-gray {
  thead {
    color: $white !important;
    background: $gray-dark !important;
  }
}

.card-wrapper-gray {
  .card-header {
    background: $gray-dark !important;
  }
}

.table-campaign {
  border-radius: 0.25rem 0.25rem 0.25rem 0.25rem;
  -moz-border-radius: 0.25rem 0.25rem 0.25rem 0.25rem;
  -webkit-border-radius: 0.25rem 0.25rem 0.25rem 0.25rem;

  thead {
    color: $white !important;
    background: $dark-1;

    tr:first-child {
      border-radius: 0.25rem 0.25rem 0 0;

      th:first-child {
        border-radius: 0.25rem 0 0 0;
        -moz-border-radius: 0.25rem 0 0 0;
        -webkit-border-radius: 0.25rem 0 0 0;
      }

      th:last-child {
        border-radius: 0 0.25rem 0 0;
        -moz-border-radius: 0 0.25rem 0 0;
        -webkit-border-radius: 0 0.25rem 0 0;
      }
    }
  }

  tr:last-child {
    border-radius: 0 0 0.25rem 0.25rem;

    td:first-child {
      border-radius: 0 0 0 0.25rem;
      -moz-border-radius: 0 0 0 0.25rem;
      -webkit-border-radius: 0 0 0 0.25rem;
    }

    td:last-child {
      border-radius: 0 0 0.25rem 0;
      -moz-border-radius: 0 0 0.25rem 0;
      -webkit-border-radius: 0 0 0.25rem 0;
    }
  }
}

.table-award {
  .table-responsive {
    box-shadow: 0px 3px 6px #00000029;
  }
}

.table-responsive {
  th {
    border: unset !important;
  }

  td {
    border-left: unset !important;
  }
}

.tr-drag {
  td {
    vertical-align: middle;
  }

  &.helperContainerClass {
    width: auto;
    border: 1px solid #efefef;
    box-shadow: 0 5px 5px -5px rgba(0, 0, 0, 0.2);
    background-color: rgba(255, 255, 255, 0.9);
    border-radius: 3px;

    &:active {
      cursor: grabbing;
    }

    &>td {
      padding: 5px;
      text-align: left;
      vertical-align: middle;
      width: 200px;
    }
  }

  &>td:nth-last-child(4) {
    width: 650px;
  }
}

.check-box-size {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding-left: 0;
  min-height: unset !important;

  .form-check-input {
    width: 1.5em;
    height: 1.5em;
    margin-right: 0.5em;
    margin-left: 0;
    margin-top: 0;
    padding-left: 0;
  }
}

.checkbox-no-label {
  .form-check-input {
    margin: 0;
  }
}

.table-winning-conditions {
  tbody>tr>td {
    padding: 0 !important;

    &>div {
      height: 2.5rem;
    }
  }

  .name-course {
    max-width: 500px;
  }

  .box-square {
    margin-right: 0.5em;
    flex-basis: 2.5rem;
    height: 100%;
    padding: 10px;
    background: $gray-dark;
    color: $white;
    text-align: center;
  }

  .diagonal-crossline {
    background: linear-gradient(to top right,
        $white calc(50% - 1px),
        $border,
        $white calc(50% + 1px));
  }
}
.mr-10{
  margin-right: 10px;
}
.modal-award {
  .valid-feedback {
    width: auto;
    white-space: pre-wrap;
  }
}

.seat-setting {
  &::after {
    @extend .miim-align-items-center;
    margin-left: 0.5em;
    content: '番　〜　当選上限数';
  }
}

.view-only {
  .form-check-input:checked[type='checkbox'] {
    background-image: unset !important;
  }

  .check-box-ct {
    input:checked {
      background-color: $black !important;
      border-color: $black !important;
    }
  }

  .table-campaign {
    @extend .header-light-gray;
  }
}

.table-campaign-list {
  thead {
    color: $white !important;
    background: $dark-1;
  }
}

thead tr {
  .hidden-sort {
    &>div:last-child {
      display: none !important;
    }
  }
}

.text-prev {
  white-space: pre;

  &-wrap {
    white-space: pre-wrap;
  }
}

.btn-group-statistic button {
  min-width: 110px;
}

.float-right {
  float: right;
}

.table-user-result {
  thead tr {
    border-bottom-width: 2px !important;
  }
}

.btn-blacklist,
.btn-whitelist {
  width: 150px !important;
  margin-left: 10px;
}

.master-record-row {
  background: $bg-master-record !important;
}

.hidden-file {
  width: 0;
  height: 0;
  overflow: hidden;
  opacity: 0;
}

// *  END CAMPAIGN

/*
  * Voucher
*/
.table-register-voucher {
  thead tr {
    border-bottom-width: 2px !important;
  }
}

/*
  End voucher
*/
